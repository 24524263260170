import { create } from 'apisauce';
import Reactotron from 'reactotron-react-js';

const http = create ({
	baseURL: 'https://bus.mriya.me/api/'
});

http.addResponseTransform ( response => {
	const { data, ok, problem, status } = response;

	if ( !ok )
	{
		const  error = { ...data, problem, status },
			{ errors } = error;

		response.error = error;

		if ( errors )
		{
			Object.keys ( errors )
				.forEach ( name => {
					if ( Array.isArray ( errors[ name ] ) )
					{
						errors[ name ] = errors[ name ].join ( ' ' );
					}
				} );
		}
	}
} );

if ( process.env.NODE_ENV === 'development' ) http.addMonitor ( Reactotron.apisauce );


export function getRoutes ()
{
	return request (
		http.get ( 'routes' )
	);
}

export function getRoute ( $id )
{
	return request (
		http.get ( 'routes/' + $id )
	);
}

export function getStops ()
{
	return request (
		http.get ( 'stops' )
	);
}

export function getTrips ()
{
	return request (
		http.get ( 'trips' )
	);
}

export function getTransport ()
{
	return request (
		http.get ( 'transport' )
	);
}


export function postMessage ( values )
{
	return request (
		http.post ( 'messages', values )
	);
}

function request ( promise, handleResult, handleError = error => ({ error }) )
{
	return promise.then ( getResultHandler ( handleResult ) )
		.catch ( handleError );
}

// (fn|arr|str) => obj
function getResultHandler ( fn = res => res )
{
	return ({ data, ok, problem, error }) => {
		if ( error ) throw error;

		if ( typeof fn === 'string' || Array.isArray ( fn ) )
		{
			const props = [].concat ( fn );

			fn = data => props.reduce (
				( res, prop ) => ({ ...res, [ prop ]: data[ prop ] }),
				{}
			);
		}

		const result = fn ( data );

		return { result };
	}
}
